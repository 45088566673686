






































































































import { Component, Vue } from 'vue-property-decorator';
import { CategoryType, CategoryTypeList, EquipmentType, ExerciseVideoStatusType, MovementType, StatusType } from '@/constants';
import { BrandDocument, BrandModel } from '@/models';
import { Helper } from '@/util';
import { canManage } from './util';

interface IBrandSearch {
  trainer: string;
  categoryList: CategoryType[];
  brandList: string[];
}

@Component({
  name: 'BrandList',
})
export default class extends Vue {
  private categoriesWithBrands: Record<string, string> = {};
  private brands: BrandDocument[] = [];
  private categoryBrands: BrandDocument[] = [];
  private results: BrandDocument[] = [];
  private lastCategory: string = (undefined as unknown) as string;
  private isSearching: boolean = false;
  private canManage: boolean = (null as unknown) as boolean;

  private search: IBrandSearch = {
    trainer: '',
    categoryList: [],
    brandList: [],
  };

  public created() {
    this.canManage = canManage;
    this.listBrands();
  }

  // Data logic
  // ==========
  private async listBrands(): Promise<void> {
    if (this.isSearching) {
      return;
    }

    this.isSearching = true;
    this.brands = await BrandModel.find({});
    this.brands.sort(this.sortDefault);
    this.isSearching = false;
    this.handleCategoryChange();
  }

  private handleCategoryChange(): void {
    const categoryWithBrandPredicate = ([category]: string[]) => this.brands.some(brand => category === brand.category);
    this.categoriesWithBrands = Object.fromEntries(Object.entries(CategoryTypeList).filter(categoryWithBrandPredicate));

    this.search.brandList = [];
    const searchCategories = this.search.categoryList;
    const categoryBrands = this.brands.filter(brand => searchCategories.length === 0 || searchCategories.includes(brand.category));
    categoryBrands.sort((a, b) => ('' + a.name).localeCompare(b.name));
    this.categoryBrands = categoryBrands;
    this.filterBrands();
  }

  private filterBrands() {
    this.results = this.brands;
    if (this.search.categoryList.length > 0) {
      this.results = this.brands.filter(brand => this.search.categoryList.includes(brand.category));
    }
    if (this.search.brandList.length > 0) {
      this.results = this.brands.filter(brand => this.search.brandList.includes(brand.id));
    }
    if (this.search.trainer) {
      this.results = this.brands.filter(brand => {
        const trainerName = brand.trainer?.name.toLowerCase() || '';
        return trainerName.indexOf(this.search.trainer.toLowerCase()) > -1;
      });
    }
  }

  private async handleDeleteRow(brand: BrandDocument) {
    try {
      await brand.remove();
      this.$message({
        message: `Brand ${brand.name} successfully deleted.`,
        type: 'success',
      });
      await this.listBrands();
    } catch (e) {
      this.$alert((e as Error).message, `Brand deletion failed`, { type: 'error' });
    }
  }

  // UI and Nav Logic
  //=================
  private showCategory(brand: BrandDocument, index: number) {
    if (index === 0 || this.lastCategory !== brand.category) {
      this.lastCategory = brand.category;
      return true;
    }

    this.lastCategory = brand.category;
    return false;
  }

  private handleBrandClickRow(row: BrandDocument) {
    this.gotoBrandEdit(row.id);
  }

  private handleBrandDblClickRow(row: BrandDocument) {
    this.gotoBrandEdit(row.id);
  }

  private gotoBrandCreate() {
    Helper.routeTo({ name: 'brand-create' });
  }

  private gotoBrandEdit(brandId: string) {
    Helper.routeTo({ name: 'brand-update', params: { id: brandId } });
  }

  private getStatus(active: boolean) {
    return active ? 'Active' : 'Inactive';
  }

  // Sort Logic
  //===========
  private sortDefault(brand1: BrandDocument, brand2: BrandDocument) {
    const result = brand1.category.toLowerCase().localeCompare(brand2.category.toLowerCase());
    if (result !== 0) {
      return result;
    }
    return brand1.name.toLowerCase().localeCompare(brand2.name.toLowerCase());
  }

  private sortName(brand1: BrandDocument, brand2: BrandDocument) {
    return brand1.name.toLowerCase().localeCompare(brand2.name.toLowerCase());
  }

  private sortTrainer(brand1: BrandDocument, brand2: BrandDocument) {
    const trainer1 = (brand1.trainer && brand1.trainer?.name) || '';
    const trainer2 = (brand2.trainer && brand2.trainer?.name) || '';
    return trainer1.toLowerCase().localeCompare(trainer2.toLowerCase());
  }

  private sortStatus(brand1: BrandDocument, brand2: BrandDocument) {
    const status1 = this.getStatus(brand1.active);
    const status2 = this.getStatus(brand2.active);
    return status1.toLowerCase().localeCompare(status2.toLowerCase());
  }
}
