



















































import { Component, Vue } from 'vue-property-decorator';
import { BrandDocument } from '@/models';
import BrandStore from '@/store/modules/brand';
import { FileUploader, GreyBox } from '@/components';
import { CategoryType, CategoryTypeList } from '@/constants';
import { canManage } from '../util';

@Component({
  name: 'BrandMetadata',
  components: {
    GreyBox,
    FileUploader,
  },
})
export default class extends Vue {
  private brand: BrandDocument = BrandStore.current as BrandDocument;
  private categoryTypes: Record<CategoryType, string> = CategoryTypeList;
  private canManage: boolean = (null as unknown) as boolean;

  private created() {
    this.canManage = canManage;
  }

  get status(): string {
    return this.brand.active ? 'Active' : 'Inactive';
  }
}
