






























































import { Component, Vue } from 'vue-property-decorator';
import ElTableDraggable from 'element-ui-el-table-draggable';
import { GreyBox } from '@/components';
import {
  WarmUpCoolDownMovementType,
  WarmUpCoolDownMovementTypeList,
  MovementType,
  BrandWorkoutSetupType,
  BrandWorkoutSetupTypeList,
  ExerciseDurationTime,
} from '@/constants';
import BrandStore from '@/store/modules/brand';
import { BrandDocument, IBrandWorkoutSetup, ExerciseDocument, ExerciseModel } from '@/models';
import { canManage } from '../util';
import { CSV } from '@/shared';

@Component({
  name: 'BrandWarmUpCoolDownExercises',
  components: {
    GreyBox,
    ElTableDraggable,
  },
})
export default class extends Vue {
  private brand: BrandDocument = (null as unknown) as BrandDocument;
  private exercises: ExerciseDocument[] = ([] as unknown) as ExerciseDocument[];
  private types: Record<BrandWorkoutSetupType, string> = (null as unknown) as Record<BrandWorkoutSetupType, string>;
  private movementTypes: Record<MovementType, string> = (null as unknown) as Record<MovementType, string>;
  private durations: number[] = (null as unknown) as number[];
  private canManage: boolean = (null as unknown) as boolean;

  public async created() {
    this.types = BrandWorkoutSetupTypeList;
    this.brand = BrandStore.current as BrandDocument;
    this.canManage = canManage;
    this.exercises = await ExerciseModel.findAllWarmUpCoolDownByBrandId(this.brand.id);

    this.exercises.sort((a, b) => (a.name as any) - (b.name as any));

    this.movementTypes = WarmUpCoolDownMovementTypeList;
    this.durations = ExerciseDurationTime;
  }

  private addToList(type: BrandWorkoutSetupType): void {
    const workoutSetup = this.brand.workoutSetup.find(x => x.type === type) as IBrandWorkoutSetup;

    workoutSetup.setup.push({
      exerciseId: '',
      movementType: WarmUpCoolDownMovementType[0],
      duration: ExerciseDurationTime[0],
    });
  }

  private removeFromList(type: BrandWorkoutSetupType, index: number): void {
    const workoutSetup = this.brand.workoutSetup.find(x => x.type === type) as IBrandWorkoutSetup;

    workoutSetup.setup.splice(index, 1);
  }

  private async downloadCSV(): Promise<void> {
    return CSV.brandWarmupCooldown(this.brand);
  }
}
