








































import { Component, Vue } from 'vue-property-decorator';
import { BrandDocument } from '@/models';
import BrandStore from '@/store/modules/brand';
import { FileUploader, GreyBox } from '@/components';
import { canManage } from '../util';

@Component({
  name: 'BrandTemplate',
  components: {
    GreyBox,
    FileUploader,
  },
})
export default class extends Vue {
  private brand: BrandDocument = (null as unknown) as BrandDocument;
  private canManage: boolean = (null as unknown) as boolean;

  private created() {
    this.brand = BrandStore.current as BrandDocument;
    this.canManage = canManage;
  }
}
