

























import { Component, Vue } from 'vue-property-decorator';
import { BrandDocument, IBrandProductMovementSetup } from '@/models';
import BrandStore from '@/store/modules/brand';
import { BrandMetadata, BrandMovementTypes, BrandWarmupExercises, BrandTemplate } from '@/views/brands/sections';
import { Helper, IStepSetup } from '@/util';
import { canManage } from './util';

@Component({
  name: 'CreateWorkout',
})
export default class extends Vue {
  private brand: BrandDocument = BrandStore.current as BrandDocument;
  private step = 0;
  private steps: Array<IStepSetup> = (undefined as unknown) as Array<IStepSetup>;
  private canManage: boolean = (null as unknown) as boolean;

  public created() {
    this.canManage = canManage;
    this.steps = [
      {
        label: 'Metadata',
        id: 'metadata',
        component: BrandMetadata,
      },
      {
        label: 'Movement types',
        id: 'movementTypes',
        component: BrandMovementTypes,
      },
      {
        label: 'Warmup & Cooldown',
        id: 'warmupCooldownExercises',
        component: BrandWarmupExercises,
      },
      {
        label: 'Template',
        id: 'template',
        component: BrandTemplate,
      },
    ];

    // Goto step if passed in query string
    const params = this.$router.currentRoute.params;
    const stepId = params.step;
    if (stepId) {
      this.gotoStep(stepId);
    }
  }

  get title(): string {
    const title = this.brand.isNew() ? `Create ${this.brand.name} Brand` : `${this.brand.name} Brand`;
    const step = this.steps[this.step];
    return `${title} - ${step.label}`;
  }

  get component(): any {
    const step = this.getStep();
    if (step.component) {
      return step.component;
    }
    return null;
  }
  private gotoStep(stepId: string) {
    const stepIndex = this.steps.findIndex(step => {
      return step.id === stepId;
    });
    if (stepIndex > -1) {
      this.step = stepIndex;
    }
  }

  private getStep(step: number = this.step) {
    return this.steps[step];
  }

  public nextStep(): void {
    const { step, steps } = this;
    if (step + 1 < steps.length) {
      this.step = step + 1;
    }
  }

  public async saveForm(): Promise<void> {
    try {
      this.removeBrandEmptyMovementTypes();

      const isNew = this.brand.isNew();

      await this.brand.validate();

      await this.brand.save();

      await Helper.routeTo({ name: 'brand-list' });

      this.$message({
        message: `Brand ${this.brand.name} successfully ${isNew ? 'created' : 'updated'}.`,
        type: 'success',
      });
    } catch (e) {
      let errorMsg = 'Error raised when saving';
      if (Array.isArray(e)) {
        const errorList = e.map(error => error.message);
        errorMsg = `"${errorList.join('" & "')}"`;
      }
      this.$message({
        message: `Save failed: ${errorMsg}`,
        type: 'error',
        duration: 5000,
      });

      console.error('ERROR', e, errorMsg);
    }
  }

  private async gotoList() {
    await Helper.routeTo({ name: 'brand-list' });
  }

  // Remove null movement types
  private removeBrandEmptyMovementTypes() {
    const productMovementSetup = this.brand.productMovementSetup;
    for (let i = 0; i < productMovementSetup.length; i++) {
      const productMovement = productMovementSetup[i];
      this.removeEmptyMovementTypes(productMovement);
    }

    this.brand.workoutSetup.forEach(workoutSetup => {
      workoutSetup.setup = workoutSetup.setup.filter(x => !!x.exerciseId);
    });
  }

  private removeEmptyMovementTypes(productMovement: IBrandProductMovementSetup): void {
    const movementTypes = productMovement.movementTypes;
    productMovement.movementTypes = productMovement.movementTypes.filter(movementType => movementType !== null);
  }

  public prevStep(): void {
    const { step } = this;
    if (step - 1 >= 0) {
      this.step = step - 1;
    }
  }

  public isLastStep(): boolean {
    return this.step + 1 === this.steps.length;
  }
}
