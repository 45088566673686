


















































import { Component, Vue } from 'vue-property-decorator';
import { GreyBox } from '@/components';
import { ProductType, ProductTypeList, ProductTypeOptions, MovementType, MovementTypeList } from '@/constants';
import BrandStore from '@/store/modules/brand';
import { BrandDocument, IBrandProductMovementSetup } from '@/models';
import { canManage } from '../util';

@Component({
  name: 'BrandMovementTypes',
  components: {
    GreyBox,
  },
})
export default class extends Vue {
  private brand: BrandDocument = BrandStore.current as BrandDocument;
  private movementTypeMap: Record<MovementType, string> = MovementTypeList;
  private productTypeMap: Record<ProductType, string> = ProductTypeList;
  private productTypes: ProductType[] = Object.keys(this.productTypeMap) as ProductType[];
  private visibleProductTypes: string[] = [];
  private canManage: boolean = (null as unknown) as boolean;

  public created() {
    this.canManage = canManage;
    this.loadBrand();
    this.getInitVisibleProductTypes();
  }

  // Accordion and UI Logic
  private getInitVisibleProductTypes(): void {
    if (this.brand.productMovementSetup.length === 0) {
      this.visibleProductTypes = []; // Should not happen
    } else {
      this.visibleProductTypes = [this.brand.productMovementSetup[0].productType];
    }
  }

  private setAllProductTypesVisible() {
    this.visibleProductTypes = this.brand.productMovementSetup.map(productMovement => productMovement.productType);
  }

  private loadBrand() {
    // Initiate New Movement Types
    for (let i = 0; i < this.productTypes.length; i++) {
      const productType = this.productTypes[i];
      const productMovement = this.getProductMovement(productType);
      if (!productMovement) {
        this.brand.productMovementSetup.push({
          productType,
          movementTypes: this.loadEmptyMovementTypes(),
        });
      } else {
        if (productMovement.movementTypes.length === 0) {
          productMovement.movementTypes = this.loadEmptyMovementTypes();
        }
      }
    }
    // Sort by Product Type
    this.brand.productMovementSetup.sort(this.sortProductMovements);
  }

  private loadEmptyMovementTypes() {
    return new Array(3).fill(null);
  }

  private sortProductMovements(productMovement1: IBrandProductMovementSetup, productMovement2: IBrandProductMovementSetup) {
    const productType1 = productMovement1.productType;
    const productType2 = productMovement2.productType;
    const index1 = this.productTypes.indexOf(productType1);
    const index2 = this.productTypes.indexOf(productType2);
    return index1 - index2;
  }

  private getProductMovement(productType: ProductType): IBrandProductMovementSetup | undefined {
    return this.brand.productMovementSetup.find(productMovement => productMovement.productType === productType);
  }

  private hasMovementTypes(productMovement: IBrandProductMovementSetup) {
    if (!productMovement) {
      return false;
    }
    const movementTypes = productMovement.movementTypes;
    if (!movementTypes) {
      return false;
    }
    return movementTypes.some(movementType => movementType !== null);
  }

  /**
   * @TODO Make general function for this
   */
  public getInheritedProductType(productType: ProductType): ProductType | null {
    const productSetupIndex = ProductTypeOptions.findIndex(x => x.key === productType);

    if (ProductTypeOptions[productSetupIndex].altInheritFrom) {
      return ProductTypeOptions[productSetupIndex].altInheritFrom as ProductType;
    }

    if (productSetupIndex > 0) {
      return ProductTypeOptions[productSetupIndex - 1].key;
    }

    return null;
  }

  public getInheritedProductMovementSetup(productType: ProductType): IBrandProductMovementSetup | null {
    const inheritedProductType = this.getInheritedProductType(productType);

    if (!inheritedProductType) {
      return null;
    }

    return this.brand.productMovementSetup.find(x => x.productType === inheritedProductType) as IBrandProductMovementSetup;
  }

  public isMovementTypeSame(productType: ProductType, index: number): boolean {
    const inheritedProductMovementSetup = this.getInheritedProductMovementSetup(productType);

    if (!inheritedProductMovementSetup) {
      return true;
    }

    const currentProductMovementSetup = this.brand.productMovementSetup.find(x => x.productType === productType) as IBrandProductMovementSetup;

    return currentProductMovementSetup.movementTypes[index] === inheritedProductMovementSetup.movementTypes[index];
  }

  private isVisibleCopyMovementTypes(index: number) {
    if (!this.canManage) {
      return false;
    }
    if (!this.brand.isNew() && !this.canCopyMovementTypes()) {
      return false;
    }
    return index === 0;
  }

  private canCopyMovementTypes() {
    const productMovementSetup = this.brand.productMovementSetup;
    if (productMovementSetup.length === 0) {
      return false;
    }
    const [firstProductMovement, ...remainingProductMovements] = productMovementSetup;
    if (!remainingProductMovements) {
      return false;
    }

    if (!this.hasMovementTypes(firstProductMovement)) {
      return false;
    }

    return remainingProductMovements.some(productMovement => !this.hasMovementTypes(productMovement));
  }

  private copyMovementTypes() {
    const productMovementSetup = this.brand.productMovementSetup;
    const [firstProductMovement, ...remainingProductMovements] = productMovementSetup;
    this.cleanMovementTypes(firstProductMovement);
    const firstMovementTypes = firstProductMovement.movementTypes;

    const emptyProductMovements = remainingProductMovements.filter(productMovement => !this.hasMovementTypes(productMovement));
    // Copy first to all Product Types
    emptyProductMovements.map(productMovement => (productMovement.movementTypes = [...firstMovementTypes]));
    this.setAllProductTypesVisible();
  }

  // Remove null movement types
  private cleanMovementTypes(productMovement: IBrandProductMovementSetup): void {
    productMovement.movementTypes = productMovement.movementTypes.filter(movementType => movementType !== null);
  }

  private deleteMovementType(movementTypes: MovementType[], index: number) {
    movementTypes.splice(index, 1);
  }

  private insertMovementType(movementTypes: any[], index: number) {
    movementTypes.splice(index, 0, null);
  }
}
